import React from 'react';

const Mask = props => {
  const { className, viewBox, style, width, height } = props;
  return (
    <svg viewBox={viewBox} className={className} style={style} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M128 0H0V128V256H128H256V128V0H128ZM128 0C198.692 0 256 57.3076 256 128C256 198.692 198.692 256 128 256C57.3076 256 0 198.692 0 128C0 57.3076 57.3076 0 128 0Z" fill="white"/>
    </svg>
  )
};

export default Mask;
