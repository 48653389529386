import React, { Component } from 'react'
import { invert, toTrue, toFalse } from '../lib/state';

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      focus: false,
      hover: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  };

  handleToggle = () => this.setState(invert('isOpen', this.state));

  handleOpen = () => this.setState(toTrue('isOpen'));

  handleClose = () => this.setState(toFalse('isOpen'));


  render() {
    const {
      disabled,
      className,
      buttonClassnames,
      containerClassnames,
      menuClassnames,
      innerMenu,
      innerButton,
    } = this.props;

    const isOpenClassNames = this.state.isOpen === true
      ? 'visible'
      : 'hidden';

    return (
      <div className={`relative ${containerClassnames}`}>
        <button
          className={`h-8 mb-0 pv-0 bg-gray white ${buttonClassnames}`}
          onClick={this.handleToggle}
          disabled={disabled}
        >
          { innerButton }
        </button>
        <div
          onClick={this.handleClose}
          className={`absolute z-999 ${isOpenClassNames} ${menuClassnames}`}>
          { innerMenu }
        </div>
      </div>
    )
  }
}






export default Dropdown
