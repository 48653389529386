import React, { Component } from 'react';
import Interface from './Interface';
import Footer from './components/Footer';


class App extends Component {
  render() {
    return (
      <main className={'container pt-8'}>
        <h1>Sigil Generator</h1>
        <div className={ 'row wrapper' }>
          <div className={'col-'}>
            <Interface />
          </div>
          <div className={'push'} />
        </div>
        {
          // <Footer />
        }
      </main>
    );
  }
}

export default App;
