import React from 'react';

const Button = ({ disabled, onClick, title, className }) =>
  <button
    className={`h-8 mb-0 pv-0 bg-gray white ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {title}
  </button>

export default Button;
