import React, { Component } from 'react';
import { sigil, stringRenderer, reactRenderer } from 'urbit-sigil-js';
import { saveAs } from 'file-saver';
import { patp } from 'urbit-ob';
import SVGO from 'svgo'
import { SHIP_TYPES } from './lib/constants';

// Local
import InputBox from './components/InputBox';
import Button from './components/Button';
import StatusButton from './components/StatusButton';
import CheckBox from './components/CheckBox';
import Dropdown from './components/Dropdown';
import Mask from './components/Mask';
import { invert, toTrue, toFalse } from './lib/state';
import {
  initCanvas,
  loadImg,
  dataURItoBlob,
  noSig,
  randShip,
  compose,
  toTitleCase,
} from './lib/helpers';


const cleanupConfig = { plugins:[
        {
          cleanupAttrs: true,
        }, {
          removeDoctype: true,
        },{
          removeXMLProcInst: true,
        },{
          removeComments: true,
        },{
          removeMetadata: true,
        },{
          removeTitle: true,
        },{
          removeDesc: true,
        },{
          removeUselessDefs: true,
        },{
          removeEditorsNSData: true,
        },{
          removeEmptyAttrs: true,
        },{
          removeHiddenElems: true,
        },{
          removeEmptyText: true,
        },{
          removeEmptyContainers: true,
        },{
          removeViewBox: false,
        },{
          cleanupEnableBackground: true,
        },{
          convertStyleToAttrs: true,
        },{
          convertColors: true,
        },{
          convertPathData: true,
        },{
          convertTransform: true,
        },{
          removeUnknownsAndDefaults: true,
        },{
          removeNonInheritableGroupAttrs: true,
        },{
          removeUselessStrokeAndFill: true,
        },{
          removeUnusedNS: true,
        },{
          cleanupIDs: true,
        },{
          cleanupNumericValues: true,
        },{
          moveElemsAttrsToGroup: true,
        },{
          moveGroupAttrsToElems: true,
        },{
          collapseGroups: true,
        },{
          removeRasterImages: false,
        },{
          mergePaths: true,
        },{
          convertShapeToPath: true,
        },{
          sortAttrs: true,
        },{
          removeDimensions: true,
        }]
      }


const COLORS = [
  ['#ffffff', '#000000'].reverse(),
  ['#ffffff', '#C80F34'].reverse(),
  ['#ffffff', '#EE5432'].reverse(),
  ['#ffffff', '#F8C134'].reverse(),
  ['#ffffff', '#286E55'].reverse(),
  ['#ffffff', '#2AA779'].reverse(),
  ['#ffffff', '#2ED196'].reverse(),
  ['#ffffff', '#190D8D'].reverse(),
  ['#ffffff', '#4330FC'].reverse(),
  ['#ffffff', '#6184FF'].reverse(),
  ['#ffffff', '#83C3FF'].reverse(),
  ['#ffffff', '#903AE6'].reverse(),
  ['#ffffff', '#EC6FF7'].reverse(),
  ['#ffffff', '#FFB0D6'].reverse(),
];


const SHIPTYPES = [
  SHIP_TYPES.PLANET,
  SHIP_TYPES.STAR,
  SHIP_TYPES.GALAXY,
];


const INITIAL_STATE = {
  input: '',
  patp: noSig('~zod'),
  mask: false,
  size: 256,
  pngSize: 1024,
  svgSize: 1024,
  colors: COLORS[0],
  invert: false,
  classOf: SHIP_TYPES.PLANET,
};


const DATA_URI_PREFIX = 'data:image/svg+xml;base64,';


class Interface extends Component {
  constructor(props) {
    super(props)
    this.pcr_ref = React.createRef();
    this.canvas = null;
    this.state = INITIAL_STATE;
  };



  makeSVG = () => {
    const { patp, svgSize, colors } = this.state;

    const config = {
      patp,
      renderer: stringRenderer,
      size: svgSize,
      colors: this.handleInvert(colors),
      margin: (54 / 256) * svgSize,
    };

    const s = sigil(config);
    return s;
  }



  handleDownloadPNG = () => {
    const { patp, pngSize, colors } = this.state;

    const _size = pngSize / window.devicePixelRatio;

    console.log(window.devicePixelRatio, _size, pngSize);

    // initialize canvas element
    this.canvas = initCanvas(this.scr_ref, { x: _size, y: _size });
    const ctx = this.canvas.getContext('2d');

    // make sigil svg and encoded into base64
    const svg = sigil({
      patp,
      renderer: stringRenderer,
      size: _size,
      colors: this.handleInvert(colors),
      margin: (54 / 256) * _size,
    });
    const svg64 = btoa(svg);
    const image64 = DATA_URI_PREFIX + svg64;

    // load this image, then
    loadImg(image64, img => {
      // draw into canvas context, convert canvas context to blob and download
      ctx.drawImage(img, 0, 0, _size, _size);
      const png = dataURItoBlob(this.canvas.toDataURL("image/png"));
      saveAs(png, `${this.state.patp}.png`);
      ctx.clearRect(0, 0, pngSize, pngSize);

    });

    return;
  };



  handleDownloadSVG = () => {
    // const svgo = new SVGO(cleanupConfig)

    const s = this.makeSVG();

    const b = new Blob([s], { type: "text/plain;charset=utf-8" });
    saveAs(b, `${this.state.patp}.svg`);


    // svgo.optimize(s).then(r => {
    //
    // })

  };



  handleToggleMask = () => this.setState(invert('mask', this.state));



  handleToggleInvert = () => {
    this.setState(invert('invert', this.state));
  };



  handleSetColor = cw => {
    this.setState({ colors: cw });
  };



  handleSetPatp = () => this.setState({ patp: noSig(this.state.input) });



  handleInvert = () => {
    const { colors, invert } = this.state;
    if (invert === true) return [colors[1], colors[0]];
    return colors;
  };



  validateNum = str => {
    if (str === '') return '';
    return parseInt(str);
  };

  randomShip = () => {
    const s = compose(noSig, patp, randShip)(this.state.classOf);
    this.setState({ patp: s });
  };


  handleSetShipType = classOf => this.setState({ classOf: classOf });


  render() {

    const maskDisplayClassnames = this.state.mask === true
      ? 'o-100'
      : 'o-0';

    return (
      <div className={'mt-2'}>

        <div className={'flex flex-column'}>
          <div className={'flex'}>
            <InputBox
              labelClassName={''}
              inputClassName={'text-mono'}
              containerClassnames={'w-64'}
              label={<div />}
              title={'Enter a @p'}
              onChange={e => this.setState({ input: e.target.value })}
              onClick={() => this.handleSetPatp()}
              currentValue={this.state.input}
              buttonText={'Generate'}
              placeholder={'~zod'}
            />


            <Button
              title={'Random'}
              className={'ml-2 mt-5'}
              onClick={() => this.randomShip()}
            />

            <Dropdown
              typeText={'Ship Class'}
              currentSelectionText={''}
              innerButton={<DropDownButton title={'Ship Class:'} stateText={toTitleCase(this.state.classOf)} /> }
              innerMenu={<TextMenu handleSelection={type => this.handleSetShipType(type)} />}
              buttonClassnames={'flex items-center'}
              containerClassnames={'ml-2 mt-5'}
              menuClassnames={''}
            />

          </div>
          <div className={'flex'}>
            <StatusButton
              title={'Mask'}
              className={'mt-5'}
              isOn={this.state.mask}
              onClick={() => this.handleToggleMask()}
            />


            <StatusButton
              title={'Invert'}
              className={'ml-2 mt-5'}
              isOn={this.state.invert}
              onClick={() => this.handleToggleInvert()}
            />

            <ColorMenu
              className={'mt-5 ml-2'}
              handleSelection={cw => this.handleSetColor(cw)}
              currentValue={this.state.colors}
            />

          </div>

          <div className={'flex'}>

            <InputBox
              labelClassName={''}
              inputClassName={'text-mono'}
              containerClassnames={'w-36'}
              label={<div/>}
              title={'PNG Size'}
              onChange={e => this.setState({ pngSize: this.validateNum(e.target.value) })}
              onClick={() => this.handleDownloadPNG()}
              currentValue={this.state.pngSize}
              buttonText={'PNG ↓'}
              placeholder={'256'}
            />


            <InputBox
              labelClassName={''}
              inputClassName={'text-mono'}
              containerClassnames={'ml-2 w-36'}
              label={<div/>}
              title={'SVG Size'}
              onChange={e => this.setState({ svgSize: this.validateNum(e.target.value) })}
              onClick={() => this.handleDownloadSVG()}
              currentValue={this.state.svgSize}
              buttonText={'SVG ↓'}
              placeholder={'256'}
            />
          </div>

        </div>
        <div className={'mt-8'}>
          <div>
            <h2 className={'mb-4 text-mono'}>{`~${this.state.patp}`}</h2>
          </div>

          <div className={'relative'}>
            <Mask
              viewBox={'0 0 256 256'}
              width={'256'}
              height={'256'}
              className={`absolute ${maskDisplayClassnames}`}
              style={{transform:'scale(1.0005)'}}
            />
            { sigil({
                patp: this.state.patp,
                colors: this.handleInvert(this.state.colors),
                renderer: reactRenderer,
                size: this.state.size,
                margin: 54,
              })
            }
          </div>
        </div>

        <canvas
          className={'super-hidden'}
          ref={ scr_ref => this.scr_ref = scr_ref }
        />

      </div>
    );
  }
}

const DropDownButton = ({ title, stateText }) => {
  return (
    <div className={'flex justify-between items-center w-36'}>
      <div className={'flex'} >
        <div className={''}>{title}</div>
        <div className={'ml-2 text-mono'}>{stateText}</div>
      </div>
      <div className={'down-triangle ml-3'} />
    </div>
  )
}


const ColorMenu = ({
  handleSelection,
  currentValue,
  className,
}) => {
  return (
    <div className={`flex ${className}`}>
    { COLORS.map(cw =>
      <button
        className={'m-0 p-0 h-8 w-8 p-2 bt-2 flex flex-center-all'}
        style={{backgroundColor: cw[0]}}
        onClick={() => handleSelection(cw)}>
        {
          cw === currentValue ? <div className={'bg-white indicator'} /> : <div/>
        }
      </button>
    ) }
    </div>
  )
}


const TextMenu = ({
  handleSelection
}) => {
  return (
    <div className={''}>
    { SHIPTYPES.map(st =>
      <button
        className={'m-0 p-0 flex h-8 w-40 p-2 btw-2 br-white bts-solid bg-gray-20 black'}
        onClick={() => handleSelection(st)}>
          <div className={'flex w-24 text-mono'}>
            {toTitleCase(st)}
          </div>
      </button>
    ) }

    </div>
  )
}

const SwatchItem = ({ c, className }) => {
  return (
    <div className={className}>
      <Swatch className={''} c={c} />
      <div>{c}</div>
    </div>
  )
}

const Swatch = ({ c, className }) => <div
  className={`w-4 h-4 block ${className}`}
  style={{backgroundColor: c}} />

export default Interface;
