import React from 'react'

const Button = ({ disabled, onClick, title, className, isOn }) => {

  const indicatorColor = isOn === true
    ? 'bg-white'
    : 'bg-gray-60';

  const buttonColor = isOn === true
    ? 'bg-blue'
    : 'bg-gray';

  return (
    <button
      className={`${buttonColor} h-8 mb-0 pv-0 white flex items-center pl-3 ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={`indicator mr-2 ${indicatorColor}`} />
      <div>{title}</div>
    </button>
  )
}


export default Button
