import { map } from 'lodash';
import { SHIP_TYPES } from './constants';
import {
  tlc,
  split,
  replace,
  tuc,
  join,
  charAt,
  substr,
} from './reset';

const initCanvas = (canvas, size) => {
  const { x, y } = size;
  let ctx = canvas.getContext('2d');

  let ratio = window.devicePixelRatio || 1;

  // default for high print resolution.
  // ratio = ratio * resMult;

  canvas.width = x * ratio;
  canvas.height = y * ratio;
  canvas.style.width = x + 'px';
  canvas.style.height = y + 'px';

  canvas.getContext('2d').scale(ratio, ratio);

  return canvas;
}


const loadImg = (src, cb) => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = () => resolve(cb(img));
  img.onerror = () => reject(`Error loading image. src: ${src}`);
  img.src = src;
});


const dataURItoBlob = dataURI => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs
  const byteString = atob(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  let ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], {type: mimeString});
  return blob;
};


const noSig = str => str.replace(/~+/g, '');


const randInt = max => Math.floor(Math.random() * Math.floor(max));


const randShip = k => {
  let b = 8;
  if (k === SHIP_TYPES.COMET) b = 128;
  if (k === SHIP_TYPES.MOON) b = 64;
  if (k === SHIP_TYPES.PLANET) b = 32;
  if (k === SHIP_TYPES.STAR) b = 16;
  if (k === SHIP_TYPES.GALAXY) b = 8;
  return randInt(Math.pow(2, b) - 1);
}


const compose = (...fs) => fs.reduceRight((pF, nF) => (...args) => nF(pF(...args)), v => v);


const toTitleCase = str => {
  const f = t => tuc(charAt(t, 0)) + tlc(substr(t, 1))
  return replace(str, /\w\S*/g, f);
}


export {
  initCanvas,
  loadImg,
  dataURItoBlob,
  noSig,
  randShip,
  compose,
  toTitleCase,
}
