

const invert = (k, state) => {
  return {
    [k]: !state[k],
  }
};


const toTrue = k => {
  return {
    [k]: true,
  }
};

const toFalse = k => {
  return {
    [k]: false,
  }
};



export {
  invert,
  toTrue,
  toFalse,
}
