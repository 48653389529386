import React from 'react'

import { version } from '../../package.json'

class Footer extends React.Component {

  render() {
    // eslint-disable-next-line
    const { setGlobalState, route } = this.props

    return (
      <footer className={'row h-13 mt-20'}>

        <div className={'col-md-3'}>
          {'Sigil Generator v' + version }
        </div>

        <div className={'col-md-3'}>
          {'Tlon'}
        </div>


      </footer>
    )
  }
}

export default Footer
