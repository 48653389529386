import React, { Component } from 'react';
import classnames from 'classnames';

class InputBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focus: false,
      hover: false,
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  };

  onFocus () {
    this.setState({ focus: true });
  }

  onBlur () {
    this.setState({ focus: false });
  }

  onMouseEnter () {
    this.setState({ hover: true });
  }

  onMouseLeave () {
    this.setState({ hover: false });
  }


  render () {
    const {
      disabled,
      autocomplete,
      autofocus,
      inputClassName,
      labelClassName,
      label,
      onChange,
      currentValue,
      placeholder,
      containerClassnames,
      onClick,
      submitDisabled,
      buttonText
    } = this.props

    const _containerClassnames = containerClassnames === undefined
      ? ''
      : containerClassnames;

    const _inputClassName = inputClassName === undefined
      ? ''
      : inputClassName;

    const buttonClasses = classnames({
      'bg-gray white': this.state.focus === false,
      'bg-blue white': this.state.focus === true,
    });

    const inputClasses = classnames({
      'br-gray': this.state.focus === false,
      'br-blue': this.state.focus === true,
    });

    return (
      <div
        className={`${_containerClassnames}`}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <label className={`h-4 ${labelClassName}`}>{label}</label>
        <div className={'flex'}>
          <input
            className={`pv-0 h-8 b-reset ${inputClasses} ${_inputClassName}`}
            placeholder={placeholder}
            type='text'
            value={currentValue}
            autoFocus={autofocus}
            autoComplete={autocomplete}
            disabled={disabled}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={e => onChange(e)} />
          <button
            className={`h-8 mb-0 pv-0 ${buttonClasses}`}
            onClick={onClick}
            disabled={submitDisabled}>{buttonText}</button>
        </div>
      </div>
    )
  }
}



export default InputBox
