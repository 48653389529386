const MIN_GALAXY = 0
const MAX_GALAXY = 255
const MIN_STAR = 256
const MAX_STAR = 65535
const MIN_PLANET = 65536
const MAX_PLANET = 4294967297

const SHIP_TYPES = {
  COMET: 'COMET',
  MOON: 'MOON',
  PLANET: 'PLANET',
  STAR: 'STAR',
  GALAXY: 'GALAXY',
}

export {
  MIN_GALAXY,
  MAX_GALAXY,
  MIN_STAR,
  MAX_STAR,
  MIN_PLANET,
  MAX_PLANET,

  SHIP_TYPES,
}
